import { assert } from '@ember/debug';
import { service } from '@ember/service';

import { LOCALE_CODES, LOCALES } from '@repo/shared-config/constants/locales';
import IntlServiceBase from 'ember-intl/services/intl';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import { differenceInCalendar } from 'qonto/utils/date';

export const defaultMoneyOptions = {
  minimumFractionDigits: 2,
  currency: CURRENCIES.default,
};

export default class IntlService extends IntlServiceBase {
  @service sentry;
  @service launchdarkly;

  get getLocales() {
    return LOCALES.filter(
      locale =>
        (this.launchdarkly.isInitialized && variation('feature--portuguese-translation')) ||
        locale.code !== 'pt'
    );
  }

  get getLocaleCodes() {
    return LOCALE_CODES.filter(
      locale =>
        (this.launchdarkly.isInitialized && variation('feature--portuguese-translation')) ||
        locale !== 'pt'
    );
  }

  /**
   * Format a number into currency
   *
   * @public
   * @method formatMoney
   *
   * @param {number} money
   * @param {Object} options
   * @param {number} options.digits - The minimum number of fraction digits to use. Possible values are from 0 to 20
   * @param {string} options.style - If 'currency' tries to prefix the amount with a currency symbol
   * @param {boolean|string} options.signus - Adds a string before the amount. If true, adds a '+' or '-' sign
   * @returns {string} A formatted currency string
   */
  formatMoney(money, options = {}) {
    assert('`style` option must not be used when calling `formatMoney()`', !options.style);

    let amount = money || 0;

    let opts = Object.assign({}, defaultMoneyOptions, options);
    let { digit } = opts;
    if (digit) {
      opts.minimumFractionDigits = Number(digit);
    }

    let { signus } = opts;
    if (signus === true) {
      signus = amount < 0 ? '-' : '+';
    }

    let formatted =
      this.formatNumber(!signus ? amount : Math.abs(amount), opts) + '\xA0' + opts.currency;

    if (signus) {
      formatted = `${signus}\xA0${formatted}`;
    }

    return formatted;
  }

  formatDateRelative(value, format = 'short') {
    let daysBeforeToday = value ? differenceInCalendar(new Date(), value, 'day') : 0;

    switch (daysBeforeToday) {
      case 0:
        return this.t('labels.today');
      case 1:
        return this.t('labels.yesterday');
      default:
        return this.formatDate(value, { format });
    }
  }

  formatCountry(value, options) {
    let regionNames = new Intl.DisplayNames(this.locale, { type: 'region', ...options });
    return regionNames.of(value);
  }

  formatNumber(value, options) {
    /**
     * Intl.NumberFormat throws errors on some browsers for some display options
     * https://github.com/WebKit/WebKit/blob/9cb35748705901155cb6d83963671c791046c035/Source/JavaScriptCore/runtime/IntlNumberFormat.cpp#L550-L586
     *
     * Fallback to reduced options if error is thrown, removing unstable options
     * cf. Google resolution: https://github.com/google/site-kit-wp/pull/3620/files
     */
    let fallbackOptions;
    try {
      return super.formatNumber(value, options);
    } catch {
      if (options.signDisplay) {
        fallbackOptions = { ...options, signDisplay: 'auto' };
      }
    }

    try {
      return super.formatNumber(value, fallbackOptions);
    } catch {
      delete options.signDisplay;
      delete options.currencyDisplay;

      return super.formatNumber(value, options);
    }
  }
}
