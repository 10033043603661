/* eslint-disable @qonto/no-import-roles-constants */
import Service, { service } from '@ember/service';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';

export default class MemberManagerService extends Service {
  @service segment;
  @service sensitiveActions;
  @service organizationManager;
  @service modals;
  @service intl;

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  handleSelectRoleTask = dropTask(async (dataContext, role, transitionToNext, pushFlow) => {
    if (!dataContext.isEditingMember) {
      return await this.handleSelectRoleInviteFlowTask.perform(
        dataContext,
        role,
        transitionToNext,
        pushFlow
      );
    }

    await this.handleSelectRoleEditMemberFlowTask.perform(
      dataContext,
      role,
      transitionToNext,
      pushFlow
    );
  });

  handleSelectRoleInviteFlowTask = dropTask(
    async (dataContext, role, transitionToNext, pushFlow) => {
      if (role.available) {
        dataContext.member.role = role.key;
        dataContext.role = role;

        if (role.key !== ROLES.MANAGER && role.key !== ROLES.ADVANCED_MANAGER) {
          dataContext.member.set('customPermissions', undefined);
          dataContext.member.set('monthlyTransferLimit', undefined);
          dataContext.member.set('perTransferLimit', undefined);
        }

        this.segment.track('team-invite_role-chosen_clicked', {
          role: role.key,
          origin: 'team_page',
        });

        dataContext.showComparison = false;

        await transitionToNext();
      } else {
        if (role.key === ROLES.ADVANCED_MANAGER) {
          // TODO implement add-on upsell with event
        }
        dataContext.upsellTrigger = [ROLES.ADMIN, ROLES.EMPLOYEE].includes(role.key)
          ? 'additionalUsers'
          : 'managerRole';

        pushFlow('subscription-change', 'discover-teams');
        this.segment.track('team_invite_upsell', {
          plan: dataContext.pricePlanCode,
          role:
            this.hasModularPricing && role.key === ROLES.MANAGER ? 'standard_manager' : role.key,
          page: this.hasModularPricing ? 'role-details' : 'role-selection',
        });
      }
    }
  );

  handleSelectRoleEditMemberFlowTask = dropTask(
    async (dataContext, role, transitionToNext, pushFlow) => {
      if (role.available) {
        dataContext.role = role;

        if (role.key !== ROLES.MANAGER && role.key !== ROLES.ADVANCED_MANAGER) {
          dataContext.currentUserSIpermission =
            dataContext.member.customPermissions?.groups?.manage_supplier_invoices;
          dataContext.member.set('customPermissions', undefined);
        }

        dataContext.showComparison = false;

        if (role.key === ROLES.ADMIN || role.key === ROLES.EMPLOYEE) {
          return this.sensitiveActions.runTask.perform(
            this.saveTask,
            dataContext,
            transitionToNext
          );
        }

        if (role.key === ROLES.REPORTING) {
          return this.modals.open('popup/confirmation', {
            title: this.intl.t('members.downgrade.title'),
            description: this.intl.t('members.downgrade.subtitle'),
            cancel: this.intl.t('btn.cancel'),
            confirm: this.intl.t('members.downgrade.cta'),
            confirmTask: this.handleConfirmSaveAccountantModalTask,
            dataContext,
            transitionToNext,
          });
        }

        await transitionToNext();
      } else {
        if (role.key === ROLES.ADVANCED_MANAGER) {
          // TODO implement add-on upsell with event
        }
        pushFlow('subscription-change', 'discover-teams');
        this.segment.track('team_invite_upsell', {
          plan: dataContext.pricePlanCode,
          role:
            this.hasModularPricing && role.key === ROLES.MANAGER ? 'standard_manager' : role.key,
          page: this.hasModularPricing ? 'role-details' : 'role-selection',
        });
      }
    }
  );

  saveTask = dropTask(async (dataContext, transitionToNext) => {
    try {
      dataContext.member.role = dataContext.role.key;

      await dataContext.member.updateRole();

      this.segment.track('team_member_role_edited', {
        originalRole: dataContext.currentUserRole,
        newRole: dataContext.member.role,
      });

      if (dataContext.member.role === ROLES.MANAGER) {
        this.segment.track('member_permissions_update_confirmed', {
          flow: 'change-role-to-manager',
        });
      }

      if (dataContext.currentUserSIpermission) {
        this.segment.track('manager-invitation_supplier-invoices-permission_off');
      }

      transitionToNext();
    } catch (error) {
      dataContext.member.rollbackAttributes();

      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  handleConfirmSaveAccountantModalTask = dropTask(
    async (close, { dataContext, transitionToNext }) => {
      let { organization } = this.organizationManager;

      await this.sensitiveActions.runTask.perform(this.saveTask, dataContext, transitionToNext);

      await organization.reload();

      await close();
    }
  );
}
