import Service, { service } from '@ember/service';
import { waitForPromise } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { dropTask, timeout } from 'ember-concurrency';

import {
  CARD_LEVELS,
  CARD_OPTIONS_CODES,
  CARD_STATUSES_ACTIVE,
  CARD_TYPES,
} from 'qonto/constants/cards';
import { CARDS_INFO } from 'qonto/constants/encryption';
import {
  apiBaseURL,
  billerBaseURL,
  billerV2Namespace,
  cardNamespace,
  cardQcpNamespace,
} from 'qonto/constants/hosts';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import {
  arrayBufferToB64,
  b64ToArrayBuffer,
  decrypt,
  deserializeB64PublicKey,
  encrypt,
  initCipherSuite,
  serializePublicKeyToB64,
} from 'qonto/utils/encryption/hpke';
import { getHpkeCardsBackendPublicKey } from 'qonto/utils/encryption/hpke-cards-public-keys';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardsManager extends Service {
  @service featuresManager;
  @service networkManager;
  @service abilities;
  @service organizationManager;
  @service sensitiveActions;
  @service sentry;
  @service store;

  /**
   * Contain some cards-related counters values at the organization level,
   * like the number of physical/virtual cards waiting for renew, etc...
   */
  @tracked counters = null;

  getEncryptedNumbersTask = dropTask(async cardId => {
    let suite = initCipherSuite();
    let { kem } = suite;

    let recipientKeyPair = await kem.generateKeyPair();

    let b64RecipientKey = await serializePublicKeyToB64(recipientKeyPair.publicKey, kem);

    let {
      enc: b64Enc,
      encrypted_data: b64EncryptedData,
      public_key_id,
    } = await this.networkManager.request(
      `${apiBaseURL}/${cardQcpNamespace}/cards/${cardId}/numbers`,
      {
        method: 'GET',
        headers: { 'X-Qonto-Client-Public-Key': b64RecipientKey },
      }
    );

    let enc = b64ToArrayBuffer(b64Enc);
    let encryptedData = b64ToArrayBuffer(b64EncryptedData);

    let b64Pk = getHpkeCardsBackendPublicKey()[public_key_id];
    let senderPublicKey = await deserializeB64PublicKey(b64Pk, kem);

    let decryptedData = await decrypt({
      enc,
      encryptedData,
      plainTextAad: cardId,
      plainTextInfo: CARDS_INFO.GET_NUMBERS,
      recipientKey: recipientKeyPair.privateKey,
      senderPublicKey,
      suite,
    });

    return JSON.parse(decryptedData);
  });

  getEncryptedPinTask = dropTask(async cardId => {
    let suite = initCipherSuite();
    let { kem } = suite;

    let recipientKeyPair = await kem.generateKeyPair();

    let b64RecipientKey = await serializePublicKeyToB64(recipientKeyPair.publicKey, kem);

    let {
      enc: b64Enc,
      encrypted_pin: b64EncryptedPin,
      public_key_id,
    } = await this.networkManager.request(`${apiBaseURL}/${cardQcpNamespace}/cards/${cardId}/pin`, {
      method: 'GET',
      headers: { 'X-Qonto-Client-Public-Key': b64RecipientKey },
    });

    let enc = b64ToArrayBuffer(b64Enc);
    let encryptedPin = b64ToArrayBuffer(b64EncryptedPin);

    let b64Pk = getHpkeCardsBackendPublicKey()[public_key_id];
    let senderPublicKey = await deserializeB64PublicKey(b64Pk, kem);

    return await decrypt({
      enc,
      encryptedData: encryptedPin,
      plainTextAad: cardId,
      plainTextInfo: CARDS_INFO.GET_PIN,
      recipientKey: recipientKeyPair.privateKey,
      senderPublicKey,
      suite,
    });
  });

  getEncryptionKeyTask = dropTask(async kem => {
    let { id: publicKeyId } = await this.networkManager.request(
      `${apiBaseURL}/${cardQcpNamespace}/cards/encryption_key`,
      { method: 'GET' }
    );

    // BE public keys are stored in Base64
    let b64Pk = getHpkeCardsBackendPublicKey()[publicKeyId];
    let publicKey = await deserializeB64PublicKey(b64Pk, kem);

    return {
      publicKey,
      publicKeyId,
    };
  });

  getIsQcpCardTask = dropTask(async cardLevel => {
    let { is_qcp: isQcp } = await this.networkManager.request(
      `${apiBaseURL}/${cardQcpNamespace}/cards/flow/${cardLevel}`,
      { method: 'GET' }
    );
    return isQcp;
  });

  postCardWithEncryptedPinTask = dropTask(async data => {
    return await this.networkManager.request(`${apiBaseURL}/${cardQcpNamespace}/cards`, {
      method: 'POST',
      data,
    });
  });

  async buildPayloadWithEncryptedPin(card) {
    let encryptedPin = await this.encryptPin({
      pin: card.pin,
      plainTextAad: card.idempotencyKey,
      plainTextInfo: CARDS_INFO.CREATE_CARD,
    });

    return JSON.stringify({
      card: card.serialize(),
      encryption_data: encryptedPin,
    });
  }

  buildPayloadWithoutPin(card) {
    return JSON.stringify({
      card: card.serialize(),
      encryption_data: null,
    });
  }

  async encryptPin({ pin, plainTextAad, plainTextInfo }) {
    let suite = initCipherSuite();
    let { kem } = suite;
    let senderKeyPair = await kem.generateKeyPair();

    // insensitive request
    let { publicKey: recipientPublicKey, publicKeyId: recipientPublicKeyId } =
      await this.getEncryptionKeyTask.perform(kem);

    // only the PIN is encrypted
    let { enc, encryptedData } = await encrypt({
      data: pin,
      plainTextAad,
      plainTextInfo,
      recipientPublicKey,
      senderKey: senderKeyPair,
      suite,
    });

    let senderPublicKey = await serializePublicKeyToB64(senderKeyPair.publicKey, kem);

    return {
      backend_public_key_id: recipientPublicKeyId,
      client_public_key: senderPublicKey,
      enc: arrayBufferToB64(enc),
      encrypted_pin: arrayBufferToB64(encryptedData),
    };
  }

  saveEncryptedPinTask = dropTask(async ({ card, encryptedPin, isResetPin }) => {
    let data = JSON.stringify(encryptedPin);
    let method = isResetPin ? 'PUT' : 'POST';

    let response = await this.networkManager.request(
      `${apiBaseURL}/${cardQcpNamespace}/cards/${card.id}/pin`,
      { method, data }
    );

    this.store.pushPayload('card', response);

    // to clean remaining PIN data in the store
    card.resetPinProperties();
  });

  updateMembershipFeatureFlagsTask = dropTask(async card => {
    let organization = await card.organization;
    let membership = await card.get('holder').reload();
    this.featuresManager.setup([...organization.features, ...membership.features]);
  });

  async fetchCardsCosts({ organizationId, cardLevels }) {
    let data = JSON.stringify({
      card: {
        organization_id: organizationId,
        card_levels: cardLevels,
      },
    });

    let { estimate } = await this.networkManager.request(
      `${billerBaseURL}/${billerV2Namespace}/cards/estimate`,
      {
        method: 'POST',
        data,
      }
    );

    //Serialize response's array into an object with card_levels as keys for easier access
    let serializeEstimate = (acc, item) => {
      let { card_level, ...rest } = item;
      return { ...acc, [card_level]: rest };
    };
    return estimate.reduce(serializeEstimate, {});
  }

  fetchCardsCostsTask = dropTask(async organizationId => {
    let cardLevelsToFetch = [
      CARD_LEVELS.STANDARD,
      CARD_LEVELS.PLUS,
      CARD_LEVELS.METAL,
      CARD_LEVELS.VIRTUAL,
    ];
    if (this.abilities.can('view flash card')) {
      cardLevelsToFetch.push(CARD_LEVELS.FLASH);
    }
    if (
      this.organizationManager.organization.hasModularPricing ||
      this.abilities.can('view advertising card')
    ) {
      cardLevelsToFetch.push(CARD_LEVELS.ADVERTISING);
    }

    let estimates = await this.fetchCardsCosts({
      organizationId,
      cardLevels: cardLevelsToFetch,
    });

    return {
      estimates,
      hasOneCardsLeft: estimates[CARD_LEVELS.STANDARD].monthly_cost === 0,
      isEstimateLoaded: true,
    };
  });

  fetchCardsRenewTask = dropTask(
    async (
      organizationId,
      address,
      shipToBusiness,
      cardUpsellLevel,
      cardId,
      cardDesign,
      typeOfPrint
    ) => {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${cardNamespace}/cards/renew`,
        {
          method: 'POST',
          body: JSON.stringify({
            address,
            card_design: cardDesign,
            card_upsell_level: cardUpsellLevel,
            card_id: cardId,
            organization_id: organizationId,
            ship_to_business: shipToBusiness,
            type_of_print: typeOfPrint,
          }),
        }
      );
      this.store.pushPayload(response);

      // we need to know how many cards were renewed and the card level renewal card
      //!\ In case of the renewal of a previous renewal card, we need to ensure the renewal card is not the card which was renewed
      // (the "renewal" property remains as true, although the card is eligible for renewal)
      let renewalCards = response.cards.filter(c => c.renewal && !c.renewed);
      let renewalCard = this.store.peekRecord('card', renewalCards[0].id);

      return {
        renewalCard,
        renewalCardsCount: renewalCards.length,
      };
    }
  );

  fetchCardUpsellTask = dropTask(
    async (
      card,
      address,
      shipToBusiness,
      cardUpsellLevel,
      cardUpsellDesign,
      cardUpsellTypeOfPrint
    ) => {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${cardNamespace}/cards/${card.id}/upsell`,
        {
          method: 'POST',
          body: JSON.stringify({
            organization_id: this.organizationManager.organization.id,
            address,
            ship_to_business: shipToBusiness,
            card_upsell_level: cardUpsellLevel,
            card_upsell_design: cardUpsellDesign,
            card_upsell_type_of_print: cardUpsellTypeOfPrint,
          }),
        }
      );
      this.store.pushPayload(response);

      return {
        upsoldCard: response.cards[0],
        upsellCard: response.cards[1],
      };
    }
  );

  fetchCardsMaxLimitsTask = dropTask(
    async (membershipId = this.organizationManager.membership.id, cardId = null) => {
      let cardsMaxLimitsUrl = `${apiBaseURL}/${cardNamespace}/cards/holders/${membershipId}/limit_ranges`;
      let params = { card_id: cardId };
      return await this.networkManager.request(cardsMaxLimitsUrl, {
        method: 'GET',
        data: cardId ? params : null,
      });
    }
  );

  fetchUserActivePhysicalCardsTask = dropTask(async () => {
    let params = {
      organization_id: this.organizationManager.organization.id,
      filters: {
        holder_id: this.organizationManager.membership.id,
        statuses: CARD_STATUSES_ACTIVE,
        card_levels: CARD_TYPES.PHYSICALS,
      },
    };
    try {
      return await this.store.query('card', params);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      return [];
    }
  });

  /**
   * Fetch the current card-related counters values that can then been read
   * from other parts of the app. Should be called when the counters values
   * are subject to change in order to limit the number of API calls.
   *
   * Membership-related counters are even more specific as they are used
   * only on member/guest routes, so we fetch them on these routes.
   */
  async fetchCounters(includeMembership) {
    try {
      this.counters = await waitForPromise(
        this.fetchCountersTask.perform(includeMembership).catch(ignoreCancelation)
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  fetchCountersTask = dropTask(async includeMembership => {
    let { organization } = this.organizationManager;
    if (organization) {
      let url = `${apiBaseURL}/${cardNamespace}/cards/counters`;
      let queryParams = { organization_id: organization.id };
      if (includeMembership) {
        queryParams.counters_list = 'all';
      }

      let response = await this.networkManager.request(url, {
        data: queryParams,
      });

      /*
       * Use destructuring to avoid erasing lastest membership-related counters
       * if there's a new call that doesn't include them. If the next call in
       * member/guest pages fail for some reason, we could at least fallback to
       * latest known value this way.
       */
      let result = { ...this.counters, ...response.card };
      return result;
    }
  });

  /**
   * Fetches cards data based on the provided parameters and card statuses.
   * @param {Object} options - The options for fetching cards data.
   * @param {Object} options.params - The parameters for the card data fetch operation.
   * @param {Array<string>} options.cardStatuses - The statuses of the cards to fetch.
   * @param {boolean} [options.shouldIncludeHolder=false] - Flag to include the card holder in the fetch operation.
   * @returns {Promise<Object>} A promise that resolves with the cards data.
   */
  fetchTabsData = async ({ params, cardStatuses, shouldIncludeHolder = false }) => {
    await timeout(DEBOUNCE_MS);

    let { organization, membership } = this.organizationManager;
    let { bankAccounts, filters, page, per_page, query, sort_by } = filterParams(params);

    filters = {
      ...filters,
      bank_account_ids: bankAccounts?.split(','),
      card_levels: params.card_levels,
      holder_id: shouldIncludeHolder ? membership.id : null,
      statuses: cardStatuses,
      team_ids: params.team_ids ?? null,
    };

    return this.store.query('card', {
      includes: ['memberships'],
      filters,
      organization_id: organization.id,
      page,
      per_page,
      query,
      sort_by,
    });
  };

  fetchCardOptionsPricesTask = dropTask(async () => {
    let options = await this.store.findAll('subscriptions-option');

    let cardsOptions = options?.filter(option =>
      Object.values(CARD_OPTIONS_CODES).includes(option.code)
    );

    let cardOptionsPrices = cardsOptions?.reduce((acc, option) => {
      let [cardLevel] = Object.entries(CARD_OPTIONS_CODES).find(cardOption => {
        return cardOption[1] === option.code;
      });

      acc[cardLevel] = option.price;
      return acc;
    }, {});

    return cardOptionsPrices;
  });
}
