import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { DEFAULT_LOCALE } from '@repo/shared-config/constants/locales';
import dayjs from 'dayjs';
import { restartableTask } from 'ember-concurrency';

async function polyfillPluralRules(locale) {
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
  if (shouldPolyfillPluralRules()) {
    await import('@formatjs/intl-pluralrules/polyfill');
  }

  if (Intl.PluralRules.polyfilled) {
    switch (locale) {
      case 'de':
        await import('@formatjs/intl-pluralrules/locale-data/de');
        break;
      case 'en':
        await import('@formatjs/intl-pluralrules/locale-data/en');
        break;
      case 'es':
        await import('@formatjs/intl-pluralrules/locale-data/es');
        break;
      case 'fr':
        await import('@formatjs/intl-pluralrules/locale-data/fr');
        break;
      case 'it':
        await import('@formatjs/intl-pluralrules/locale-data/it');
        break;
      case 'pt':
        await import('@formatjs/intl-pluralrules/locale-data/pt');
        break;
    }
  }
}

async function getCanonicalLocalesPolyfill() {
  if (shouldPolyfillCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
}

async function polyfillDisplayNames(locale) {
  let unsupportedLocale = shouldPolyfillDisplayNames(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-displaynames/polyfill-force');

  switch (locale) {
    case 'de':
      await import('@formatjs/intl-displaynames/locale-data/de');
      break;
    case 'en':
      await import('@formatjs/intl-displaynames/locale-data/en');
      break;
    case 'es':
      await import('@formatjs/intl-displaynames/locale-data/es');
      break;
    case 'fr':
      await import('@formatjs/intl-displaynames/locale-data/fr');
      break;
    case 'it':
      await import('@formatjs/intl-displaynames/locale-data/it');
      break;
    case 'pt':
      await import('@formatjs/intl-displaynames/locale-data/pt');
      break;
  }
}

async function importTranslations(locale) {
  return await import(
    /* webpackChunkName: "locale.[request]" */
    `/translations/${locale}.json`
  );
}

/**
 * Returns formatted string into array
 *
 * @public
 * @method parseLocaleString
 *
 * @param  {String} string
 * @returns {Array} Parsed string
 */
export function parseLocaleString(string) {
  return string ? string.toLowerCase().split(/[-_]/) : [];
}

export default class LocalManager extends Service {
  @service cookies;
  @service zendeskWidget;
  @service intl;

  @tracked locale;

  /**
   * Get the browser language
   *
   * @public
   * @method getBrowserLocale
   * @returns {String} Browser language
   */
  getBrowserLocale() {
    return navigator.language;
  }

  /**
   * Returns supported browser local
   *
   * @public
   * @method getSupportedBrowserLocale
   * @returns {string} Array of supported languages
   */
  getSupportedBrowserLocale() {
    let browserLocale = this.getBrowserLocale();
    if (this.intl.getLocaleCodes.includes(browserLocale)) {
      return browserLocale;
    }

    return DEFAULT_LOCALE;
  }

  /**
   * Returns true if the locale is supported
   *
   * @private
   * @method _isLocaleSupported
   * @param  {String} locale Locale to check
   * @returns {Boolean}
   */
  _isLocaleSupported(locale) {
    return this.intl.getLocaleCodes.includes(locale);
  }

  /**
   * Reads the locale in the cookie and return the proper locale string
   *
   * @public
   * @method readLocalFromCookie
   * @returns {String} Locale to use
   */
  readLocalFromCookie() {
    let cookieLocale = this.cookies.read('_qonto-locale');
    let parsedBrowserLocale = parseLocaleString(this.getBrowserLocale())[0];

    if (this._isLocaleSupported(cookieLocale)) {
      return cookieLocale;
    } else if (this._isLocaleSupported(parsedBrowserLocale)) {
      return parsedBrowserLocale;
    }
    return this.getSupportedBrowserLocale();
  }

  /**
   * Set the locale
   *
   * @public
   * @method setLocale
   *
   * @param  {String} locale Locale to set for the application
   * @returns void
   */
  async setLocale(locale = this.readLocalFromCookie()) {
    await this._setLocaleTask.perform(locale);
  }

  _setLocaleTask = restartableTask(async locale => {
    try {
      // Load the polyfill 1st BEFORE loading the translations
      await Promise.all([
        polyfillPluralRules(locale),
        polyfillDisplayNames(locale),
        getCanonicalLocalesPolyfill(),
      ]);
    } catch {
      // there is not benefit in a network error
    }

    if (locale === this.locale) {
      return;
    }

    let translations = await importTranslations(locale);
    this.intl.addTranslations(locale, translations);

    dayjs.locale(locale);
    this.intl.setLocale(locale);
    this.locale = locale;
    this.zendeskWidget.localeChange(locale);
  });

  /**
   * Function only meant to be used for catastrophic errors in case translations could not be loaded.
   * Will provide the English translations needed for the error page to function.
   */
  setupFallbackTranslations() {
    this.locale = DEFAULT_LOCALE;
    this.intl.setLocale(this.locale);
    this.intl.addTranslations(this.locale, {
      'empty-states': {
        server_error:
          "Your account isn't available at the moment. However, your transfers and direct debits will be executed as usual.",
        server_error_title: "We're improving the performance of your Qonto app",
      },
      btn: {
        back_to_qonto: 'Go back to the app',
      },
    });
  }
}
