import Model from '@ember-data/model';
import { assert } from '@ember/debug';
import { action, set } from '@ember/object';
import { getOwner } from '@ember/owner';
import Service, { service } from '@ember/service';
import { DEBUG } from '@glimmer/env';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, Task, waitForQueue } from 'ember-concurrency';
import { parse, stringify } from 'flatted';
import { TrackedObject } from 'tracked-built-ins';

import { adjustFlowDescription } from 'qonto/utils/adjust-flow-description';
import isFunction from 'qonto/utils/is-function';
import isThenable from 'qonto/utils/is-thenable';
import NavigationContextDll from 'qonto/utils/navigation-context-dll';
import serializeRecordToPOJO from 'qonto/utils/serialize-record-to-pojo';
import { getSessionStorageItem, setSessionStorageItem } from 'qonto/utils/session-storage';

export const NAVIGATION_CONTEXT_KEY = 'NAVIGATION_CONTEXT';
export const DATA_CONTEXT_KEY = 'DATA_CONTEXT';
export const FORWARD_STEP_KEY = 'FORWARD_STEP';

function mergeDataContext(dataContext, contextToMerge) {
  for (let newAttribute in contextToMerge) {
    assert(
      'flow-service: A flow dataContext must not contain functions',
      typeof contextToMerge[newAttribute] !== 'function' || newAttribute === 'actions'
    );
    assert(
      'flow-service: dataContext should not contain an ec task',
      !(contextToMerge[newAttribute] instanceof Task)
    );
    dataContext[newAttribute] = contextToMerge[newAttribute] ?? dataContext[newAttribute];
  }
  return dataContext;
}

function setNextStepId(flowDescription, value) {
  let { nextStepId } = flowDescription[value.flowName].steps[value.id];
  if (nextStepId) {
    value.nextStepId = nextStepId;
  }
}

export default class FlowService extends Service {
  flowDescription;
  flowSetupClasses;
  forwardStep = null;
  onAbort = null;
  onComplete = null;
  beforeRestoreTask = null;
  onSuccessToast = null;

  _lastStepId = null;
  _reviewStep = null;
  _beforePushFlowResult = null;

  @tracked isInitialised = false;
  @tracked isPersistenceEnabled = false;

  @service flowLinkManager;
  @service router;
  @service segment;
  @service store;

  @tracked dataContext = new TrackedObject({});
  @tracked navigationContext = new NavigationContextDll();

  async setup({ flowDescription, flowSetupClasses, flowName, stepId }) {
    assert('Flow description {flowDescription} is required!', flowDescription);
    this.flowDescription = adjustFlowDescription(flowDescription);
    this.flowSetupClasses = flowSetupClasses;

    try {
      this._checkFlowNameExists(flowName);
      this._checkFlowSetupExists(flowName);
      this._checkStepExists(flowName, stepId);
    } catch (error) {
      this.router.replaceWith('/404');
      // eslint-disable-next-line no-console
      if (DEBUG) console.error(error);
      return;
    }

    this.isPersistenceEnabled = Boolean(flowDescription[flowName]?.options?.enablePersistence);
    let FlowSetupClass = flowSetupClasses[flowName];
    let flowSetup = new FlowSetupClass(getOwner(this));

    let maybeTransition = await flowSetup.beforeFlow?.({ dataContext: this.dataContext, stepId });
    if (maybeTransition) {
      return;
    }

    // Set the current flow's restoration and success toast callbacks on the service
    // - `beforeRestoreTask` may be defined and so needed in the initialization of the global context
    // - `onSuccessToast` may be defined if the current flow is secondary
    let { beforeRestoreTask, onSuccessToast } = flowSetup;
    this.beforeRestoreTask = beforeRestoreTask;
    this.onSuccessToast = onSuccessToast;

    await this._initializeGlobalContext(flowSetup, flowName, stepId);

    // Determine if the current flow is primary or not, based on the value of
    // the `navigationContext` after the initilization of the global context
    let primaryFlowSetup;
    if (flowName === this.navigationContext.head.value.flowName) {
      primaryFlowSetup = flowSetup;
    } else {
      let PrimaryFlowSetupClass = flowSetupClasses[this.navigationContext.head.value.flowName];
      primaryFlowSetup = new PrimaryFlowSetupClass(getOwner(this));
    }

    // Set the remaining set of callbacks on the service
    let { onAbort, onAbortTask, onComplete } = primaryFlowSetup;
    this._checkOnAbortCallbacksExist(onAbort, onAbortTask, flowName);
    assert('Flow setup {onComplete} is required!', onComplete);
    this.onAbort = onAbort;
    this.onAbortTask = onAbortTask;
    this.onComplete = onComplete;
  }

  _checkOnAbortCallbacksExist(onAbort, onAbortTask, flowName) {
    assert(
      `Please define {onAbort} or {onAbortTask} in the ${flowName} flow setup!`,
      onAbort || onAbortTask
    );
    assert(
      `You cannot define both {onAbort} and {onAbortTask}in the ${flowName} flow setup!`,
      !(onAbort && onAbortTask)
    );
  }

  get currentStep() {
    return this.navigationContext.tail;
  }

  get flowName() {
    return this.currentStep?.value?.flowName;
  }

  get currentStepIsFirst() {
    return this.navigationContext.length === 1;
  }

  get stepList() {
    // eslint-disable-next-line ember/no-array-prototype-extensions
    return this.navigationContext.toArray();
  }

  get nextStepId() {
    return isFunction(this.currentStep?.value?.nextStepId)
      ? this.currentStep.value.nextStepId(this.dataContext, this.stepList)
      : this.currentStep.value.nextStepId;
  }

  get nextStepValue() {
    return this.flowDescription[this.flowName].steps[this.nextStepId];
  }

  get previousStep() {
    return this.currentStep?.previous;
  }

  get currentFlowIsPrimary() {
    return this.flowName === this.navigationContext.head.value.flowName;
  }

  get isPrimaryFlowLastStep() {
    return this.currentFlowIsPrimary && !this.currentStep.value.nextStepId;
  }

  get isSecondaryFlowLastStep() {
    return !this.currentFlowIsPrimary && !this.nextStepValue?.nextStepId;
  }

  get hasForwardStepInStorage() {
    return getSessionStorageItem(FORWARD_STEP_KEY) !== null;
  }

  get hasNavigationContextInStorage() {
    return getSessionStorageItem(NAVIGATION_CONTEXT_KEY) !== null;
  }

  get hasDataContextInStorage() {
    return getSessionStorageItem(DATA_CONTEXT_KEY) !== null;
  }

  get isSingleStepFlow() {
    return (
      this.navigationContext.length === 1 &&
      !this.nextStepValue?.nextStepId &&
      !this.dataContext.isMultipleStepsFlow
    );
  }

  get shouldCompleteWithSuccessToast() {
    return this.currentFlowIsPrimary && this.isSingleStepFlow && isFunction(this.onSuccessToast);
  }

  @action
  next() {
    this.navigationContext.backStack.empty();

    if (this._reviewStep) this._reviewStep = null;
    if (this._lastStepId) this._lastStepId = null;

    if (this.shouldCompleteWithSuccessToast) {
      this.onSuccessToast(this.dataContext, this.stepList);
      return this.complete();
    }

    if (this.isPrimaryFlowLastStep) {
      return this.complete();
    } else if (this.isSecondaryFlowLastStep) {
      this.onSuccessToast(this.dataContext, this.stepList);
      // Pop all secondary flow nodes
      this.navigationContext.popFlow();

      // Refresh the primary flow dataContext after completing the secondary flow
      // Merge the refreshed data context into the global dataContext
      let { flowName } = this.currentStep.value;
      let FlowSetupClass = this.flowSetupClasses[flowName];

      let { onSuccessToast } = new FlowSetupClass(getOwner(this), this.dataContext);
      this.onSuccessToast = onSuccessToast;

      if (this.forwardStep) {
        // We append the forward step that has been defined in this.forwardStep when pushForwardFlow has been called.
        // Like this, we can display to the user the next step when coming back to the primary flow.
        this.navigationContext.append(this.forwardStep);
        this.forwardStep = null;
      }
    } else {
      this.navigationContext.append(this.nextStepValue);
    }
    this._notifyNavigationContextChange();
    this._transitionToCurrentStep();
  }

  @action
  back() {
    this.navigationContext.pop();
    this._notifyNavigationContextChange();
    this._transitionToCurrentStep();
  }

  @action
  backToStep(stepId, flowName) {
    if (!flowName) flowName = this.flowName;

    try {
      this._checkFlowNameExists(flowName);
      this._checkStepExists(flowName, stepId);
    } catch (error) {
      this.router.replaceWith('/404');
      // eslint-disable-next-line no-console
      if (DEBUG) console.error(error);
      return;
    }

    // Memoize the id of the step where the action is called from
    this._reviewStep = this.currentStep.value.id;

    this.navigationContext.popUntilStep(stepId, flowName);
    this._notifyNavigationContextChange();
    this._transitionToCurrentStep();
  }

  /**
   * Navigates the user to a step in a secondary flow
   * This action is to be called in a step component of a flow that is "secondary" to the current flow
   */
  @action
  pushFlow(flowName, stepId) {
    return this.pushFlowTask.perform(flowName, stepId);
  }

  pushFlowTask = restartableTask(async (flowName, stepId) => {
    try {
      this._checkFlowNameAndStepExists(flowName, stepId);
    } catch (error) {
      this.router.replaceWith('/404');
      // eslint-disable-next-line no-console
      if (DEBUG) console.error(error);
      return;
    }
    await this._beforePushFlow(flowName, stepId);

    this.navigationContext.appendFlow(this.flowDescription[flowName].steps[stepId]);

    this._afterPushFlow(flowName, stepId);
  });

  /**
   * Navigates the user to a step in a secondary flow
   * This action is to be called in a step component of a flow that is "secondary" to the current flow
   * Ater the secondary flow is finished successfully, the dll will point to the forward step in the primary flow (relatively to the step that initiated the secondary flow).
   * If it is aborted, the dll will point to the step that initiated the secondary flow.
   */
  @action
  pushForwardFlow(flowName, stepId) {
    return this.pushForwardFlowTask.perform(flowName, stepId);
  }

  pushForwardFlowTask = restartableTask(async (flowName, stepId) => {
    await this._beforePushFlow(flowName, stepId);

    this.forwardStep = this.nextStepValue;
    this.navigationContext.appendFlow(this.flowDescription[flowName].steps[stepId]);
    this.navigationContext.backStack.empty();

    this._afterPushFlow();
  });

  async _beforePushFlow(flowName, stepId) {
    try {
      this.navigationContext.backStack.empty();
      this._lastStepId = null;
      this._checkFlowNameExists(flowName);
      this._checkFlowSetupExists(flowName);
      this._checkStepExists(flowName, stepId);
    } catch (error) {
      this.router.replaceWith('/404');
      // eslint-disable-next-line no-console
      if (DEBUG) console.error(error);
      return;
    }
    assert(
      `pushFlow must not be called with a step (${stepId}) that belongs to the same flow (${flowName}) as the current step`,
      this.flowName !== flowName
    );

    // Get the secondary flow's initial dataContext and merge it with the global dataContext
    // Set the onSuccessToast callback on the flow service
    //
    // Only the onSuccessToast callback is needed from a secondary flow
    // The abort, complete callbacks are provided by the primary flow setup
    let FlowSetupClass = this.flowSetupClasses[flowName];
    let flowSetupClass = new FlowSetupClass(getOwner(this));
    let { dataContext, onSuccessToast } = flowSetupClass;
    assert(`Flow setup {onSuccessToast} is required!`, onSuccessToast);
    this.onSuccessToast = onSuccessToast;
    this._beforePushFlowResult = await flowSetupClass.beforeFlow?.({ dataContext, stepId });
    this.dataContext = mergeDataContext(this.dataContext, dataContext);
  }

  _afterPushFlow() {
    this._notifyNavigationContextChange();
    this._transitionToCurrentStep();
  }

  _checkFlowNameExists(flowName) {
    let flow = this.flowDescription[flowName];

    if (!flow) {
      throw new Error(`The flowDescription doesn't contain the '${flowName}' flowName.`);
    }
  }

  _checkStepExists(flowName, stepId) {
    let flow = this.flowDescription[flowName];
    let stepValue = flow ? flow.steps[stepId] : null;

    if (!stepValue) {
      throw new Error(`The flowDescription doesn't contain the '${stepId}' stepId.`);
    }

    if (!stepValue.componentName) {
      throw new Error(`The flowDescription doesn't contain any componentName.`);
    }
  }

  _checkFlowNameAndStepExists(flowName, stepId) {
    this._checkFlowNameExists(flowName);
    this._checkStepExists(flowName, stepId);
  }

  _checkFlowSetupExists(flowName) {
    let flowSetup = this.flowSetupClasses[flowName];
    assert(`The flowSetupClass doesn't contain the '${flowName}' setupClass.`, flowSetup);
  }

  _transitionToCurrentStep({ replace = false, queryParams = {} } = {}) {
    // Abort transition if we transitioned inside the beforePushFlow method
    if (this._beforePushFlowResult?.routeName) {
      return;
    }
    let routeName = 'flows';
    let params = [this.flowName, this.currentStep.value.id];

    if (replace) {
      return this.router.replaceWith(routeName, ...params, { queryParams });
    }
    return this.router.transitionTo(routeName, ...params, { queryParams });
  }

  _notifyNavigationContextChange() {
    // Since the navigationContext is a plain object, the tracking system would not detect changes when adding or removing nodes internally.
    // This is needed to trigger re-renders on the template when the navigationContext changes.
    // see https://guides.emberjs.com/release/upgrading/current-edition/tracked-properties/#toc_when-to-use-get-and-set
    set(this, 'navigationContext', this.navigationContext);
  }

  setDataContext(context) {
    this.dataContext = context;
  }

  /**
   * Updates navigationContext when the url is changed using browser back/forward buttons
   */
  async updateFromUrlChange(params) {
    let { stepId, name } = params;
    if (!stepId || !name) return;

    this._persistNavigationContext();
    this._persistDataContext();
    this._persistForwardStep();

    if (this.flowName === name && this.previousStep?.value?.id === stepId) {
      if (this.isPrimaryFlowLastStep) {
        this.navigationContext.backStack.empty();
        await this.restartFlowTask.perform();
      } else {
        this.navigationContext.pop();
        this._notifyNavigationContextChange();
      }
      return;
    }

    // support browser back button after using restart CTA
    if (this.flowName === name && stepId === this._lastStepId) {
      await this._safeBack();
      return;
    }

    // support browser back button after a redirection to a previous step within a flow
    if (this.flowName === name && stepId === this._reviewStep) {
      await this._safeBack();
      return;
    }

    let nodeLength = this.navigationContext.restoreUntilStep({ flowName: name, stepId });
    if (nodeLength) {
      this._notifyNavigationContextChange();
      return;
    }

    // when the user uses the browser's back button right after completing a secondary flow
    if (this.flowName !== name) {
      await this._safeBack();
    }
  }

  /**
   * Allows to go back to the previous step or to abort the flow if there is no previous step
   */
  async _safeBack() {
    if (this.navigationContext.length === 1) {
      await this.abortTask.perform();
      this.clearStorage();
    } else {
      this.back();
    }
  }

  _persistForwardStep() {
    if (!this.isPersistenceEnabled || !this.forwardStep) return;

    let stringifiedForwardStep = stringify(this.forwardStep);
    setSessionStorageItem(FORWARD_STEP_KEY, stringifiedForwardStep);
  }

  _persistNavigationContext() {
    let stringifiedNavigationContextJson = stringify(this.navigationContext);
    setSessionStorageItem(NAVIGATION_CONTEXT_KEY, stringifiedNavigationContextJson);
  }

  _persistDataContext() {
    if (!this.isPersistenceEnabled) {
      return;
    }
    let dataContext = {};

    Object.entries(this.dataContext).forEach(([key, value]) => {
      if (value instanceof Model) {
        dataContext[key] = serializeRecordToPOJO(value);
      } else {
        dataContext[key] = value;
      }
    });

    let stringifiedDataContext = stringify(dataContext);
    setSessionStorageItem(DATA_CONTEXT_KEY, stringifiedDataContext);
  }

  _restoreForwardStep() {
    if (this.hasForwardStepInStorage) {
      let parsedForwardStep = parse(getSessionStorageItem(FORWARD_STEP_KEY));
      if (parsedForwardStep) {
        this.forwardStep = parsedForwardStep;
        setNextStepId(this.flowDescription, this.forwardStep);
      }
    }
  }

  _restoreNavigationContext(parsedNavigationContext) {
    let navigationContextDll = new NavigationContextDll();

    if (parsedNavigationContext.length) {
      // Append the first node (the current step i.e the step at which the user refreshed the page)
      // If the step is on a secondary flow, parsedNavigationContext.tail.next points to the parent step.
      // If the step is on the primary flow, parsedNavigationContext.tail.next is null.
      let { value, next } = parsedNavigationContext.tail;
      setNextStepId(this.flowDescription, value);
      navigationContextDll.append(value);

      // Save the id of the parent step to the current step if there's one.
      let parentNodeId = next?.value.id;

      // Traverse the parsedNavigationContext backwards
      let currentNode = parsedNavigationContext.tail.previous;
      while (currentNode) {
        setNextStepId(this.flowDescription, currentNode.value);
        navigationContextDll.prepend(currentNode.value);

        // Set .next pointer on the tail if the current head is its parent step node
        if (parentNodeId && navigationContextDll.head?.value.id === parentNodeId) {
          navigationContextDll.tail.next = navigationContextDll.head;
        }
        currentNode = currentNode.previous;
      }
    }

    this.navigationContext = navigationContextDll;
  }

  async _restoreDataContext() {
    let parsedDataContext = parse(getSessionStorageItem(DATA_CONTEXT_KEY));
    let dataContextEntries = Object.entries(parsedDataContext);
    if (!dataContextEntries.length) {
      return;
    }

    await this.beforeRestoreTask?.perform(parsedDataContext);

    this.segment.track('fif_restore_data_context', {
      flowName: this.flowName,
      isPrimary: this.currentFlowIsPrimary,
      stepId: this.currentStep.value.id,
      stepIndex: this.navigationContext.length,
    });

    for (let [key, value] of dataContextEntries) {
      if (value && value.isSerializedEmberModel) {
        let { id, type, attributes, relationships } = value;
        let model;

        if (id === null) {
          model = this.store.createRecord(type, attributes);
        } else {
          model = this.store.peekRecord(type, id);

          for (let [key, restoredValue] of Object.entries(attributes)) {
            if (model[key] !== restoredValue) {
              model[key] = restoredValue;
            }
          }
        }

        let filteredRelationships = Object.entries(relationships).filter(([, value]) => value);

        for (let relationship of filteredRelationships) {
          let [name, value] = relationship;
          if (Array.isArray(value)) {
            model[name] = value.map(({ id: relationshipId, type: relationshipType }) =>
              this.store.peekRecord(relationshipType, relationshipId)
            );
          } else {
            let { id: relationshipId, type: relationshipType } = value;
            model[name] = this.store.peekRecord(relationshipType, relationshipId);
          }
        }

        this.dataContext[key] = model;
      } else {
        this.dataContext[key] = value;
      }
    }
  }

  async _initializeGlobalContext(flowSetup, flowName, stepId) {
    if (this.hasNavigationContextInStorage) {
      let parsedNavigationContext = parse(getSessionStorageItem(NAVIGATION_CONTEXT_KEY));
      let { head, tail } = parsedNavigationContext;
      let { flowName: storageFlowName, id: storageStepId } = tail.value;

      let shouldRestore =
        this.isPersistenceEnabled &&
        this.hasDataContextInStorage &&
        flowName === storageFlowName &&
        stepId === storageStepId;

      let shouldRestart;
      if (!this.isPersistenceEnabled) {
        let storagePrimaryFlowName = head.value.flowName;
        shouldRestart = flowName === storagePrimaryFlowName;
        if (!shouldRestart) {
          // We want to do an additional check to see if the current flow is a secondary flow
          // by comparing the content of the parsed navigation context in storage.
          shouldRestart = flowName === tail.value.flowName;
          if (shouldRestart) {
            // Since we need to restart a primary flow and not the current flow (which has been interpreted
            // as a secondary flow), we need to setup the primary flow based on the flow name of the head node
            // described in the navigation context.
            let FlowSetupClass = this.flowSetupClasses[storagePrimaryFlowName];
            let primaryFlowSetup = new FlowSetupClass(getOwner(this));
            flowSetup = primaryFlowSetup;
            await flowSetup.beforeFlow();
          }
        }
      }

      if (shouldRestore) {
        this._restoreNavigationContext(parsedNavigationContext);
        await this._restoreDataContext();
        this._restoreForwardStep();
      } else if (shouldRestart) {
        let navigationContext = new NavigationContextDll();
        navigationContext.append(parsedNavigationContext.head.value);
        this._restoreNavigationContext(navigationContext);
        this.dataContext = flowSetup.dataContext;
        this.clearStorage();
        this._transitionToCurrentStep({ replace: true });
      } else {
        this._resetGlobalContext();
        this.clearStorage();
        this._createGlobalContext(flowSetup, flowName, stepId);
        this._notifyNavigationContextChange();
      }
    } else {
      this._createGlobalContext(flowSetup, flowName, stepId);
      this._notifyNavigationContextChange();
    }

    this.isInitialised = true;
  }

  _createGlobalContext(flowSetup, flowName, stepId) {
    this.dataContext = mergeDataContext(this.dataContext, flowSetup.dataContext);
    this.navigationContext.append(this.flowDescription[flowName].steps[stepId]);
  }

  restartFlowTask = dropTask(async ({ initialStepId } = {}) => {
    let { value } = this.navigationContext.head;
    let flowName = value.flowName;
    let stepId = initialStepId ?? value.id;
    await this.transitionToFlowTask.perform({ flowName, stepId });
    await waitForQueue('routerTransitions');
  });

  transitionToFlowTask = dropTask(async ({ flowName, stepId, queryParams = {} }) => {
    let { id: lastStepId } = this.navigationContext.tail.value;
    this.reset();
    this._lastStepId = lastStepId;

    await this.flowLinkManager.transitionTo({ name: flowName, stepId, queryParams });

    this._notifyNavigationContextChange();
  });

  @action
  complete() {
    let maybeTransition = this.onComplete(this.dataContext);

    if (isThenable(maybeTransition)) {
      return maybeTransition.then(() => this.reset());
    }

    this.reset();
  }

  abortTask = dropTask(async () => {
    if (this.onAbortTask) {
      await this._onAbortTask.linked().perform();
    } else {
      this._onAbort();
    }
  });

  _onAbortTask = dropTask(async () => {
    let shouldReset = await this.onAbortTask
      .linked()
      .perform(this.dataContext, this.currentStep.value);
    if (shouldReset !== false) {
      this.reset();
    }
  });

  _onAbort() {
    this.onAbort(this.dataContext, this.currentStep.value);
    this.reset();
  }

  _resetGlobalContext() {
    this.navigationContext.removeAll();
    this.dataContext = new TrackedObject({});
  }

  clearStorage() {
    sessionStorage.removeItem(NAVIGATION_CONTEXT_KEY);
    sessionStorage.removeItem(DATA_CONTEXT_KEY);
    sessionStorage.removeItem(FORWARD_STEP_KEY);
  }

  reset() {
    this.onAbort = null;
    this.onComplete = null;
    this.beforeRestoreTask = null;
    this.isInitialised = false;
    this.isPersistenceEnabled = false;
    this._lastStepId = null;
    this._resetGlobalContext();
  }
}
